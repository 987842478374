import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EventsActionButtions from './EventsActionButtions'
import EventSplitsActionButtions from './EventSplitsActionButtions';
import SimpleSelectInput from '../../../../components/commonComponents/SimpleSelectInput';
import lodash from 'lodash';
import { setAssetSummaryOverviewDateFilter } from '../../../../redux/slices/assets/assetSummarySlice';
import { isAddAssetAllowedToLoggedUser } from '../../../../util/commonFunctions';
import AddAssetIcon from '../../../../components/icons/AddAssetIcon';
import { showEditAssetModal } from '../../../../redux/slices/assets/assetSummarySlice';
import { CompressOutlined } from '@mui/icons-material';

function TabsButtons(props) {
  const dispatch = useDispatch();

  const assetIrrOverTimeGraphDateFilterOptions = useSelector(state => state.assetSummary.assetIrrOverTimeGraphDateFilterOptions)
  const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)
  const user = useSelector(state => state.auth.user)

  if(typeof myVariable != 'object' && !assetSummaryOverviewDateFilterValue.hasOwnProperty('value') && assetIrrOverTimeGraphDateFilterOptions && assetIrrOverTimeGraphDateFilterOptions.length > 0) {
    dispatch(setAssetSummaryOverviewDateFilter({assetSummaryOverviewDateFilterValue: assetIrrOverTimeGraphDateFilterOptions[0]}))
  }

  const handleChangeSelector = (selectedValue) => {
    dispatch(setAssetSummaryOverviewDateFilter({assetSummaryOverviewDateFilterValue: selectedValue}))
  }

  const openEditAssetModal = () => {
    dispatch(showEditAssetModal())
  }

  return (
    <div className='tabs-container'>
        <div className='button-groups'>
            { props.investment_type && <button onClick={ () => props.toggleTab('overview') } className={'tab-button ' + `${props.activeTab === 'overview' ? 'active' : ''}`}>Overview</button>}
            <button onClick={ () => props.toggleTab('asset_details') } className={'tab-button ' + `${props.activeTab === 'asset_details' ? 'active' : ''}`}>Asset Details</button>
            <button onClick={ () => props.toggleTab('capital_summary') } className={'tab-button ' + `${props.activeTab === 'capital_summary' ? 'active' : ''}`}>Capital Summary</button>
            <button onClick={ () => props.toggleTab('events') } className={'tab-button ' + `${props.activeTab === 'events' ? 'active' : ''}`}>Events</button>
            <button onClick={ () => props.toggleTab('event_split') } className={'tab-button ' + `${props.activeTab === 'event_split' ? 'active' : ''}`}>Event Splits</button>
        </div>
        { props.activeTab === 'events' && <EventsActionButtions asset_id={props.asset_id} /> }  
        { props.activeTab === 'event_split' && <EventSplitsActionButtions asset_id={props.asset_id} /> }  
        { props.activeTab === 'overview' && 
          <div className='floating-selector-view'>
            { assetSummaryOverviewDateFilterValue && assetSummaryOverviewDateFilterValue.hasOwnProperty('value') &&
                <SimpleSelectInput showLabel="true" placeholder="Date" showTickIconInMenu={true} className="portfolio-filter-category select-filter" selectedBackgroundColor="#F9FAFB" selectedColor="black" selectedValue={ lodash.find(assetIrrOverTimeGraphDateFilterOptions, function(option) { return option.value === assetSummaryOverviewDateFilterValue.value }) } options={ assetIrrOverTimeGraphDateFilterOptions } isFormik={false} handleOnChange={ handleChangeSelector } /> }
          </div>
        }
        { isAddAssetAllowedToLoggedUser(user) && props.activeTab === 'asset_details' && <button className="edit-asset-summary-button" onClick={ openEditAssetModal }><AddAssetIcon />Edit Asset</button> }
    </div>
  )
}

export default TabsButtons